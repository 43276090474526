.home {
  color: aliceblue;
  font-size: 1.4rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-left: 20px;
}

.brand {
  font-size: 20px;
}


.typewriter-text {
  font-family: 'Dancing Script', fantasy;

}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7%;
  width: 95%;
}

.animation {
  width: 200vw;

}

p {
  font-size: 50px;
}

.resume-button {
  border-radius: 10px;
  margin-top: 40px;
  font-size: 2rem;
  background-color: blueviolet;
  height: 50px;
  width: 50%;
  margin-left: 2%;
  color: aliceblue;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .container {
    padding-left: 5%;
    width: 95%;
  }

  .animation {
    position: absolute;
    width: 50vw;
    margin-left: 20%;
    margin-top: 88vh;
  }

  p {
    font-size: 40px;
  }

  .resume-button {
    font-size: 1.5rem;
    height: 40px;
  }
}

@media screen and (max-width: 400px) {
  .brand {
    font-size: 18px;
    margin-left: -5%;
  }

  .typewriter-text {
    margin-top: 20px;
    font-size: 30px;
  }

  .animation {
    position: absolute;
    width: 50vw;
    margin-left: 20%;
    margin-top: 88vh;
  }

  .resume-button {
    font-size: 1rem;
    height: 40px;
    margin-left: 23%;
    margin-top: 5%;
  }
}