@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

@font-face {
    font-family: 'Holligate Signature Demo';
    src: url('../../assets/projects/Holligate\ Signature\ Demo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Outfit", Arial, Helvetica, sans-serif;
}

body {
    background-image: url('https://images.unsplash.com/photo-1623520527569-fee1da87f598?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #37096e;
    padding: 20px 60px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}

nav ul {


    display: flex;
    width: 100%;
    justify-content: flex-end;
    list-style: none;
    align-items: center;
}



.brand-title {
    font-size: 1.5rem;
    margin: .5rem;
    color: rgba(155, 15, 186, 0.996);
    font-family: 'Holligate Signature Demo', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;

}



.navbar-links li {

    list-style: none;

}

.navbar-links li a {
    text-decoration: none;
    color: #fff;
    padding: 1rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    font-size: 1.5rem;


}

nav a {
    height: 100%;
    padding-left: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: rgb(88, 25, 99);

}

.navbar-links li:hover {
    background-color: rgba(173, 151, 195, 0.764);
    border-radius: 10px;
}

.navbar-links li a.active {
    color: rgba(195, 181, 209, 0.764)
}

nav li:first-child {
    margin-right: auto;
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(215, 191, 220, 0.725);
    backdrop-filter: blur(10px);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.01);
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.sidebar li {
    width: 100%;
}

.sidebar a {
    width: 100%
}

.menu-button {
    display: none;
    ;
}


@media (max-width: 900px) {
    .hideOnMobile {
        display: none;
    }

    .menu-button {
        display: block;
    }
}


@media (max-width: 480px) {


    .menu-button {
        padding-left: 0px;
    }

    .hideOnMobile {
        display: none;
    }

    .brand-title {
        font-size: 1rem;
    }
}

@media (max-width: 320px) {
    .sidebar {
        width: 100%;
    }

    .menu-button {
        padding-left: 0px;
    }

    .brand-title {
        font-size: 1rem;
    }
}