.aboutPage {
    display: flex;
    flex-direction: column;
}

.acontainer {
    /* position: relative; */
    background-color: rgba(12, 12, 12, 0.6);
    border-radius: 15px;
    /* padding: 73px;
    margin-top: 129px; */
    z-index: 1;
    /* margin-left: 10%;
    margin-right: 10%; */
}


.atitle {
    color: white;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3%;
}

.acontent {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}


.aphoto {

    width: 13vw;
    /* height: 200%; */
    border-radius: 5%;
    margin-left: 10%;
    margin-top: 10px;

}

.acard1 {
    color: aliceblue;
    margin: 5px;
    font-size: large;
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 17px;
    background: #0a1935;
    border-radius: 10px;
    padding: 24px;
    background-image: linear-gradient(90deg,
            rgba(165, 215, 232, 0.42) 0%,
            rgba(255, 255, 255, 0) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
    width: 45%;
    margin-left: 35px;
    margin-right: 35px;
    height: 50%;

}

.acard1:hover {
    background-size: 100% 100%;
}

.acard2 {
    color: aliceblue;

    font-size: large;
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 17px;
    background: #0a1935;
    border-radius: 10px;
    padding: 24px;
    background-image: linear-gradient(90deg,
            rgba(165, 215, 232, 0.42) 0%,
            rgba(255, 255, 255, 0) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
    width: 45%;
    height: 50%;
    margin-left: 35px;
    margin-right: 35px;
    /* margin-bottom: 30%; */
}

.acard2:hover {
    background-size: 100% 100%;
}

h4 {

    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.alogo {
    width: 15%;
    display: flex;
}

.alogo1 {
    width: 15%;
    height: auto;
}

.aedu-section {
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.cardContainer {
    display: flex;
    flex-direction: row;
}

.aaboutItem {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    list-style: none;
    padding: 25px;
    background-image: linear-gradient(90deg,
            rgba(165, 215, 232, 0.42) 0%,
            rgba(255, 255, 255, 0) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
    margin-left: 5%;
    margin-right: 5%;
    color: aliceblue;
    /* background-color: #0a1935; */
}

/* .aaboutItem:hover {
    background-size: 100% 100%;
} */

.aaboutItem h3 {
    font-size: 25px;
    font-weight: 600;
}

.about {
    font-size: 25px;
    text-align: justify;
    width: 100%;

}

.aedu-title {
    color: aliceblue;
    font-size: 2rem;
    flex: 1 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;

}


.aeducation-section {

    color: white;


}


@media screen and (max-width: 830px) {

    .acontainer {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .acontent {
        display: flex;
        flex-direction: column;
    }

    .aaboutItems {
        margin-top: 29px;
    }

    .aaboutItem {
        padding-left: 0;
        padding-right: 0;
    }

}

@media screen and (max-width: 520px) {
    .about {
        padding-left: 0;
        padding-right: 0;
        font-size: 20px;
    }

    /* .aphotodiv {
        width: 100vw;
    } */

    .acard1 {
        margin-top: 450px;
    }

    .acard2 {
        margin-top: 450px;
    }
}

@media screen and (max-width: 400px) {
    .about {
        padding-left: 0;
        padding-right: 0;
        font-size: 20px;
    }

    .aphotodiv {
        display: flex;
        justify-content: center;
        margin-right: 7px;
        margin-bottom: -60px;
    }

    .aphoto {
        width: 100px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
    }

    .acard1,
    .acard2 {
        width: 100vw;
        margin-top: 0px;
        margin-left: 0px;
        font-size: 15px;
    }

    .atitle,
    .aedu-title {
        font-size: 25px;
    }

    .about {
        font-size: 15px;
    }
}